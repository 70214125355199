import React from "react";
import { Form } from "semantic-ui-react";

export const MultiDropdownSearchableComponent = ({
  label,
  name,
  placeholder,
  options,
  setValue,
  getValues,
}) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <Form.Dropdown
          name={name}
          placeholder={placeholder}
          fluid
          multiple
          search
          selection
          defaultValue={getValues(name)}
          options={options}
          onChange={async (e, { name, value }) => {
            setValue(name, value, { required: true });
          }}
        />
      </div>
    </>
  );
};
