import { checkFollowerFollowing } from "api/follows";
import { Cake, PencilRuler } from "lucide-react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utility/functions";
import { ensureHttpPrefix } from "utility/functions";

export const checkIfFollower = async (
  user_id,
  friend_user_id,
  setIsFollower,
  setIsLoading
) => {
  checkFollowerFollowing(user_id, friend_user_id).then((follow_data) => {
    if (follow_data.length === 0) {
      setIsFollower(false);
    } else {
      setIsFollower(true);
    }
    setIsLoading(false);
  });
};

export default function UserProfileDataUI({ myProfileData }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="h-[207px] mt-10 flex-col justify-start items-start gap-4 flex">
        <div className="justify-start items-start gap-[45px] inline-flex">
          <div className="w-[52px] h-[45px] relative">
            <div className="w-[18px] h-[18px] left-0 top-0 absolute">
              <Cake size={18} className="text-primary-light" />
            </div>
            <div className="left-[2px] top-[21px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
              26
            </div>
            <div className="left-[24px] top-0 absolute text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
              {t("age")}
            </div>
          </div>
          <div className="w-28 h-[45px] relative">
            <div className="w-[18px] h-[18px] left-0 top-0 absolute">
              <Cake size={18} className="text-primary-light" />
            </div>
            <div className="left-[2px] top-[21px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
              {myProfileData.dob}
            </div>
            <div className="left-[24px] top-0 absolute text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
              {t("birthdate")}
            </div>
          </div>
          <div className="w-[87px] h-[45px] relative">
            <div className="w-[18px] h-[18px] left-0 top-0 absolute">
              <PencilRuler size={18} className="text-primary-light" />
            </div>
            <div className="left-[2px] top-[21px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
              {myProfileData.height}
            </div>
            <div className="left-[24px] top-0 absolute text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
              {t("height")}
            </div>
          </div>
        </div>
        <div className="w-full h-6 relative">
          <div className="w-[18px] h-[18px] left-0  absolute">
            <Cake size={18} className="text-primary-light" />
          </div>
          <div className="left-[28px] top-0 absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
            {myProfileData.highest_education}
          </div>
        </div>
        <div className="flex-col justify-start items-start flex">
          <div className="justify-start items-center gap-[7px] inline-flex">
            <div className=" relative">
              <Cake size={18} className="text-primary-light" />
            </div>
            <div className="text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
              {t("businessWorkplaceName")}
            </div>
          </div>
          <div className="text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
            {myProfileData.company_name}
          </div>
        </div>
        <div className="justify-start items-start gap-[25px] inline-flex">
          <div className="flex-col justify-start items-start inline-flex">
            <div className="justify-start items-center gap-1.5 inline-flex">
              <div className=" relative">
                <Cake size={18} className="text-primary-light" />
              </div>
              <div className="text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
                {t("designation")}
              </div>
            </div>
            <div className="text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
              {myProfileData.company_role}
            </div>
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="justify-start items-center gap-1.5 inline-flex">
              <div className=" relative">
                <Cake size={18} className="text-primary-light" />
              </div>
              <div className="text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
                {t("income")}
              </div>
            </div>
            <div className="text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
              Rs. {myProfileData.income}/-
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="w-max flex flex-row  mt-4">
          <Cake size={18} className="text-primary-light mr-2" />
          {t("addedOn")}
        </div>
        <div className="text-black text-opacity-70 text-base font-semibold ">
          {formatDate(myProfileData.created_at)}
        </div>
      </div>
      <div className="flex flex-wrap gap-2  mt-4">
        {myProfileData.mangal_status === "No" ? (
          <></>
        ) : (
          <div className="py-1 bg-[#FFCD6B] px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#856931]">
            {myProfileData.mangal_status} - Mangal
          </div>
        )}

        <div className="py-1 bg-[#FFC8D5] px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#984156]">
          {myProfileData.married_status}
        </div>
        {myProfileData.disability === "Yes" ? (
          <div className="py-1 bg-[#C8CDFF] px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#3E4586]">
            Disabled
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="w-max flex flex-row  mt-4 justify-center items-center">
        <div className="w-[129.15px] text-black text-opacity-40 text-base font-normal font-['Poppins'] leading-normal">
          {t("parentsDetails")}
        </div>
        <div className="w-[209px] h-[0px]   border border-gray-200"></div>
      </div>
      <div>
        <div className="w-max flex flex-row  mt-4">
          <Cake size={18} className="text-primary-light mr-2" />{" "}
          {t("parentsDetails")}
        </div>
        <div className="text-black text-opacity-70 text-base font-semibold ">
          {myProfileData.parent_full_name}
        </div>
      </div>
      <div>
        <div className="w-max flex flex-row  mt-4">
          <Cake size={18} className="text-primary-light mr-2" />{" "}
          {t("designation")}
        </div>
        <div className="text-black text-opacity-70 text-base font-semibold ">
          {myProfileData.parent_occupation}
        </div>
      </div>
      <div>
        <div className="w-max flex flex-row  mt-4">
          <Cake size={18} className="text-primary-light mr-2" /> {t("address")}
        </div>
        <div className="text-black text-opacity-70 text-base font-semibold ">
          {myProfileData.parent_address}
        </div>
      </div>
      <div>
        <div className="w-max flex flex-row  mt-4">
          <Cake size={18} className="text-primary-light mr-2" /> {t("phone")}
        </div>
        <div className="text-black text-opacity-70 text-base font-semibold ">
          {myProfileData.parent_number}
        </div>
      </div>
      <div className="self-stretch mt-6 h-[183px] relative">
        <div className="w-[338px] h-6 left-0 top-0 absolute">
          <div className="w-[111px] left-0 top-0 absolute text-black text-opacity-40 text-base font-normal font-['Poppins'] leading-normal">
            {t("mamaDetails")}
          </div>
          <div className="w-[218px] h-[0px] left-[120px] top-[13px] absolute border border-gray-200"></div>
        </div>
        <div className="w-[338px] h-[141px] left-0 top-[42px] absolute flex-col justify-start items-start gap-3 inline-flex">
          <div className="text-black text-base font-medium font-['Poppins'] leading-normal">
            {myProfileData.mama_full_name}
          </div>
          <div className="justify-start items-start gap-20 inline-flex">
            <div className="w-24 h-[47px] relative">
              <div className="w-[95px] h-[47px] left-[1px] top-0 absolute">
                <div className="left-0 top-[23px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
                  {/* {myProfileData.mama_full_name} */}
                </div>
                <div className="left-[24px] top-0 absolute text-black text-opacity-70 text-[13px] font-normal font-['Poppins'] leading-tight">
                  Hometown
                </div>
              </div>
              <div className="w-[18px] h-[18px] left-0  absolute">
                <Cake size={18} className="text-primary-light" />
              </div>
            </div>
            <div className="w-[157px] h-[46px] relative">
              <div className="w-[153px] h-[46px] left-[4px] top-0 absolute">
                <div className="left-0 top-[22px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
                  {myProfileData.mama_kul}
                </div>
                <div className="left-[18px] top-0 absolute text-black text-opacity-70 text-[13px] font-normal font-['Poppins'] leading-tight">
                  {t("mamaKul")}
                </div>
              </div>
              <div className="w-[18px] h-[18px] left-0 absolute">
                <Cake size={18} className="text-primary-light" />
              </div>
            </div>
          </div>
          <div className="w-full h-[46px] relative">
            <div className="w-full h-[46px] left-[1px] top-0 absolute">
              <div className="left-0 top-[22px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
                {myProfileData.mama_phone}
              </div>
              <div className="left-[18px] top-0 absolute text-black text-opacity-70 text-[13px] font-normal font-['Poppins'] leading-tight">
                {t("contactNo")}
              </div>
            </div>
            <div className="left-0  absolute">
              <Cake size={18} className="text-primary-light" />
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch mt-4 h-[147px] relative">
        <div className="w-[338px] h-6 left-0 top-0 absolute">
          <div className="w-[45px] left-0 top-0 absolute text-black text-opacity-40 text-base font-normal font-['Poppins'] leading-normal">
            {t("other")}
          </div>
          <div className="w-[282px] h-[0px] left-[56px] top-[13px] absolute border border-gray-200"></div>
        </div>
        <div className="w-[226px] h-[105px] left-0 top-[42px] absolute flex-col justify-start items-start gap-3 inline-flex">
          <div className="w-12 h-[46px] relative">
            <div className="w-[18px] h-[18px] left-0  absolute">
              <Cake size={18} className="text-primary-light" />
            </div>
            <div className="w-[47px] h-[46px] left-[1px] top-0 absolute">
              <div className="left-0 top-[22px] absolute text-black text-opacity-70 text-base font-semibold font-['Poppins'] leading-normal">
                {myProfileData.caste}
              </div>
              <div className="w-5 h-[21px] left-[24px] top-0 absolute text-black text-opacity-70 text-[13px] font-normal font-['Poppins'] leading-tight">
                {t("kul")}
              </div>
            </div>
          </div>
          <div className="w-full mt-16 mb-5">
            <div className="w-full flex flex-row ">
              <Cake size={18} className="text-primary-light mr-2" />{" "}
              {t("expectation")}
            </div>
            <div className="w-[300px] text-black text-opacity-70 text-base font-semibold font-['Poppins']">
              {myProfileData.expectation}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch mt-20 h-[147px] ">
        <div className="w-[338px] h-6 flex flex-row justify-between items-center">
          <div className="w-[45px] text-black text-opacity-40 text-base font-normal font-['Poppins'] leading-normal">
            {t("social")}
          </div>
          <div className="w-[282px] h-[0px] border border-gray-200"></div>
        </div>
        <div className="w-[226px] h-[105px]  flex-col justify-start items-start gap-3 inline-flex">
          <div className="flex justify-center mt-4 space-x-5">
            {myProfileData.facebook && myProfileData.facebook !== "" && (
              <a
                href={ensureHttpPrefix(myProfileData.facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="facebook"
                  src="https://img.icons8.com/fluent/30/000000/facebook-new.png"
                />
              </a>
            )}

            {myProfileData.linkedin && myProfileData.linkedin !== "" && (
              <a
                href={ensureHttpPrefix(myProfileData.linkedin)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="linkedin"
                  src="https://img.icons8.com/fluent/30/000000/linkedin-2.png"
                />
              </a>
            )}

            {myProfileData.instagram && myProfileData.instagram !== "" && (
              <a
                href={ensureHttpPrefix(myProfileData.instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="instagram"
                  src="https://img.icons8.com/fluent/30/000000/instagram-new.png"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>

    // <div className="mt-10 flex flex-col gap-4">
    //   <div className="flex flex-row items-start gap-[45px]">
    //     <div className="flex flex-col justify-start items-start">
    //       <Cake size={18} className="text-primary-light" />
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         26
    //       </div>
    //       <div className="text-black text-opacity-70 text-sm font-normal font-poppins leading-[21px]">
    //         {t("age")}
    //       </div>
    //     </div>
    //     <div className="flex flex-col justify-start items-start">
    //       <Cake size={18} className="text-primary-light" />
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         {myProfileData.dob}
    //       </div>
    //       <div className="text-black text-opacity-70 text-sm font-normal font-poppins leading-[21px]">
    //         {t("birthdate")}
    //       </div>
    //     </div>
    //     <div className="flex flex-col justify-start items-start">
    //       <PencilRuler size={18} className="text-primary-light" />
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         {myProfileData.height}
    //       </div>
    //       <div className="text-black text-opacity-70 text-sm font-normal font-poppins leading-[21px]">
    //         {t("height")}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="flex flex-row items-start">
    //     <Cake size={18} className="text-primary-light" />
    //     <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //       {myProfileData.highest_education}
    //     </div>
    //   </div>
    //   <div className="flex flex-col justify-start items-start">
    //     <div className="flex flex-row items-center gap-[7px]">
    //       <Cake size={18} className="text-primary-light" />
    //       <div className="text-black text-opacity-70 text-sm font-normal font-poppins leading-[21px]">
    //         {t("businessWorkplaceName")}
    //       </div>
    //     </div>
    //     <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //       {myProfileData.company_name}
    //     </div>
    //   </div>
    //   <div className="flex flex-row items-start gap-[25px]">
    //     <div className="flex flex-col justify-start items-start">
    //       <div className="flex flex-row items-center gap-1.5">
    //         <Cake size={18} className="text-primary-light" />
    //         <div className="text-black text-opacity-70 text-sm font-normal font-poppins leading-[21px]">
    //           {t("designation")}
    //         </div>
    //       </div>
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         {myProfileData.company_role}
    //       </div>
    //     </div>
    //     <div className="flex flex-col justify-start items-start">
    //       <div className="flex flex-row items-center gap-1.5">
    //         <Cake size={18} className="text-primary-light" />
    //         <div className="text-black text-opacity-70 text-sm font-normal font-poppins leading-[21px]">
    //           {t("income")}
    //         </div>
    //       </div>
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         Rs. {myProfileData.income}/-
    //       </div>
    //     </div>
    //   </div>
    //   <div className="mt-4">
    //     <Cake size={18} className="text-primary-light mr-2" />
    //     {t("addedOn")}
    //     <div className="text-black text-opacity-70 text-base font-semibold">
    //       {formatDate(myProfileData.created_at)}
    //     </div>
    //   </div>
    //   <div className="flex flex-wrap gap-2 mt-4">
    //     {myProfileData.mangal_status === "No" ? null : (
    //       <div className="py-1 bg-[#FFCD6B] px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#856931]">
    //         {myProfileData.mangal_status} - Mangal
    //       </div>
    //     )}
    //     <div className="py-1 bg-[#FFC8D5] px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#984156]">
    //       {myProfileData.married_status}
    //     </div>
    //     {myProfileData.disability === "Yes" ? (
    //       <div className="py-1 bg-[#C8CDFF] px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#3E4586]">
    //         Disabled
    //       </div>
    //     ) : null}
    //   </div>
    //   <div className="mt-4">
    //     <Cake size={18} className="text-primary-light mr-2" />
    //     {t("parentsDetails")}
    //     <div className="text-black text-opacity-70 text-base font-semibold">
    //       {myProfileData.parent_full_name}
    //     </div>
    //     <div className="text-black text-opacity-70 text-base font-semibold">
    //       {myProfileData.parent_occupation}
    //     </div>
    //     <div className="text-black text-opacity-70 text-base font-semibold">
    //       {myProfileData.parent_address}
    //     </div>
    //     <div className="text-black text-opacity-70 text-base font-semibold">
    //       {myProfileData.parent_number}
    //     </div>
    //   </div>
    //   <div className="mt-4 flex flex-col gap-3">
    //     <div className="text-black text-base font-medium font-poppins leading-normal">
    //       {myProfileData.mama_full_name}
    //     </div>
    //     <div className="flex flex-row justify-start items-start gap-20">
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         Hometown
    //       </div>
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //         {myProfileData.mama_kul}
    //       </div>
    //     </div>
    //     <div className="text-black text-opacity-70 text-base font-semibold font-poppins leading-normal">
    //       {myProfileData.mama_phone}
    //     </div>
    //   </div>
    //   <div className="mt-4 flex flex-col gap-3">
    //     <div className="text-black text-opacity-70 text-base font-semibold font-poppins">
    //       {myProfileData.caste}
    //     </div>
    //     <div className="mt-16 mb-5">
    //       <div className="flex flex-row">
    //         <Cake size={18} className="text-primary-light mr-2" />
    //         {t("expectation")}
    //       </div>
    //       <div className="text-black text-opacity-70 text-base font-semibold font-poppins">
    //         {myProfileData.expectation}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="mt-20 h-[147px]">
    //     <div className="flex flex-row justify-between items-center">
    //       <div className="text-black text-opacity-40 text-base font-normal font-poppins">
    //         {t("social")}
    //       </div>
    //       <div className="flex justify-center mt-4 space-x-5">
    //         {myProfileData.facebook && (
    //           <a
    //             href={ensureHttpPrefix(myProfileData.facebook)}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <img
    //               alt="facebook"
    //               src="https://img.icons8.com/fluent/30/000000/facebook-new.png"
    //             />
    //           </a>
    //         )}
    //         {myProfileData.linkedin && (
    //           <a
    //             href={ensureHttpPrefix(myProfileData.linkedin)}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <img
    //               alt="linkedin"
    //               src="https://img.icons8.com/fluent/30/000000/linkedin-2.png"
    //             />
    //           </a>
    //         )}
    //         {myProfileData.instagram && (
    //           <a
    //             href={ensureHttpPrefix(myProfileData.instagram)}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <img
    //               alt="instagram"
    //               src="https://img.icons8.com/fluent/30/000000/instagram-new.png"
    //             />
    //           </a>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
