import { useNavigate } from "react-router-dom";
import React from "react";
import logo from "assets/matrimony_logo.png";

import ButtonComponent from "components/ButtonComponent";
import { useTranslation } from "react-i18next";
import TranslationDropdown from "components/TranslationDropdown";

export default function LandingPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className=" w-[100%] h-[100vh] flex justify-center flex-col">
      <div className="w-full h-[35%] bg-gradient-to-b from-red-600 to-amber-500 rounded-b-full flex items-center justify-center">
        <img alt="logo" src={logo} />
      </div>
      <div className="w-full h-[40%] flex flex-col items-center text-center justify-around px-8 py-4 ">
        <p className="text-2xl w-56 font-semibold">{t("appName")}</p>
        <p className="text-xl w-80">{t("appDescription")}</p>

        <ButtonComponent
          onClick={() => {
            navigate("login");
          }}
          name={t("loginWithEmail")}
          type="button"
        />
      </div>
      <div className="w-full flex justify-center">
        <TranslationDropdown />
      </div>

      <div className="w-full h-[25%] flex flex-row justify-between items-end px-20 pb-14 text-rose-500 text-sm">
        <a href="/terms_and_conditions" className="">
          {t("termsOfUse")}
        </a>
        <a href="/privacy_policy" className=" text-rose-500 text-sm">
          {t("privacyPolicy")} v0.15
        </a>
      </div>
    </div>
  );
}
