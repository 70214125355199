import { createClient } from "@supabase/supabase-js";

const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

// Provide a custom `fetch` implementation as an option
export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  global: {
    fetch: (...args) => fetch(...args),
  },
});
