// import React, { useState, useEffect } from "react";

// import { useSelector } from "react-redux";
// import Loader from "components/Loader";

// import { getMedia, deleteMedia, uploadMedia } from "api/profile";
// import { TiDelete } from "react-icons/ti";
// import { useTranslation } from "react-i18next";
// import imageCompression from "browser-image-compression";

// const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

// export default function YourPhotosForm() {
//   const { t } = useTranslation();

//   const [isLoading, setIsLoading] = useState(true);
//   const userData = useSelector((state) => state.userData);

//   const [media, setMedia] = useState([]);

//   async function uploadImage(e) {
//     setIsLoading(true);
//     let file = e.target.files[0];

//     // Options for the image compression
//     const options = {
//       maxSizeMB: 0.1, // (100KB)
//       maxWidthOrHeight: 1920, // Adjust according to your needs
//       useWebWorker: true,
//     };
//     try {
//       const compressedFile = await imageCompression(file, options);

//       uploadMedia(userData.data.id, compressedFile).then(() => {
//         getMedia(userData.data.id, 6).then((media_list) => {
//           if (media_list) {
//             setMedia(media_list);
//           }
//           setIsLoading(false);
//         });
//       });
//     } catch (error) {
//       console.error("Error while compressing the image: ", error);
//       setIsLoading(false); // Ensure loading is false if compression fails
//     }
//   }

//   async function deleteImage(link_url) {
//     setIsLoading(true);

//     deleteMedia(link_url).then(() => {
//       getMedia(userData.data.id, 6).then((media_list) => {
//         if (media_list) {
//           setMedia(media_list);
//         }
//         setIsLoading(false);
//       });
//     });
//   }

//   useEffect(() => {
//     getMedia(userData.data.id, 6).then((media_list) => {
//       if (media_list) {
//         setMedia(media_list);
//       }
//       setIsLoading(false);
//     });
//   }, [userData.data.id]);

//   function checkMediaLength() {
//     return media.length;
//   }

//   return (
//     <>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <div className="flex justify-center w-full items-center flex-col">
//           <div className="grid grid-cols-3 gap-y-4  w-full mt-4">
//             {media.map((media) => {
//               return (
//                 <div
//                   key={media.name}
//                   className="relative w-24 h-24 flex items-center justify-center "
//                 >
//                   <img
//                     className=" h-20"
//                     alt="Loading"
//                     src={`${SUPABASE_URL}/storage/v1/object/public/avatars/${userData.data.id}/${media.name}`}
//                   />
//                   <TiDelete
//                     onClick={() => {
//                       deleteImage(`${userData.data.id}/${media.name}`);
//                     }}
//                     color="red"
//                     size="26"
//                     className=" absolute top-0 right-0 m-1 cursor-pointer"
//                   />
//                 </div>
//               );
//             })}
//           </div>

//           {checkMediaLength() < 6 ? (
//             <div className="w-max h-max text-primary-light font-semibold cursor-pointer relative px-4 py-1 mt-8">
//               + {t("addPhotos")}
//               <input
//                 className="absolute top-0 left-0 w-full opacity-0 cursor-pointer"
//                 type="file"
//                 accept="image/jpeg, image/png"
//                 onChange={(e) => uploadImage(e)}
//               />
//             </div>
//           ) : (
//             <></>
//           )}
//         </div>
//       )}
//     </>
//   );
// }

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import Loader from "components/Loader";

import { getMedia, deleteMedia, uploadMedia } from "api/profile";
import { TiDelete } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

export default function YourPhotosForm() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state) => state.userData);

  const [media, setMedia] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  async function handleFilesUpload() {
    setIsLoading(true);
    const options = {
      maxSizeMB: 0.2, // (100KB)
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    // Use Promise.all to wait for all images to be compressed and uploaded
    Promise.all(
      selectedFiles.map(async (file) => {
        try {
          const compressedFile = await imageCompression(file, options);
          return uploadMedia(userData.data.id, compressedFile);
        } catch (error) {
          console.error("Error while compressing the image: ", error);
        }
      })
    )
      .then(() => {
        getMedia(userData.data.id, 6).then((media_list) => {
          if (media_list) {
            setMedia(media_list);
          }
          setIsLoading(false);
          setSelectedFiles([]); // Clear the selection after upload
        });
      })
      .catch((error) => {
        console.error("Error during the upload process: ", error);
        setIsLoading(false);
      });
  }

  async function deleteImage(link_url) {
    setIsLoading(true);

    deleteMedia(link_url).then(() => {
      getMedia(userData.data.id, 6).then((media_list) => {
        if (media_list) {
          setMedia(media_list);
        }
        setIsLoading(false);
      });
    });
  }

  useEffect(() => {
    getMedia(userData.data.id, 6).then((media_list) => {
      if (media_list) {
        setMedia(media_list);
      }
      setIsLoading(false);
    });
  }, [userData.data.id]);

  function handleFileChange(e) {
    const newFiles = Array.from(e.target.files);
    // Calculate the remaining number of files the user can select
    const maxAllowedFiles = 6;
    const remainingSlots =
      maxAllowedFiles - media.length - selectedFiles.length;

    if (remainingSlots <= 0) {
      alert(
        "You cannot upload more photos. You have reached the limit of 6 photos."
      );
      return; // Exit the function if no slots are remaining
    }

    // Limit the new files to the remaining slots, if necessary
    const filesToAdd = newFiles.slice(0, remainingSlots);

    if (filesToAdd.length < newFiles.length) {
      alert(`You can only upload ${remainingSlots} more photo(s).`);
    }

    // Update the state with the new files, without exceeding the total limit
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesToAdd]);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex justify-center w-full items-center flex-col">
          <div className="grid grid-cols-3 gap-y-4  w-full mt-4">
            {media.map((media) => (
              <div
                key={media.name}
                className="relative w-24 h-24 flex items-center justify-center"
              >
                <img
                  className="h-20 rounded-lg"
                  alt="Uploaded"
                  src={`${SUPABASE_URL}/storage/v1/object/public/avatars/${userData.data.id}/${media.name}`}
                />
                <TiDelete
                  onClick={() =>
                    deleteImage(`${userData.data.id}/${media.name}`)
                  }
                  color="red"
                  size="26"
                  className="absolute top-0 right-0  cursor-pointer"
                />
              </div>
            ))}
          </div>

          <div className="w-max h-max text-primary-light font-semibold cursor-pointer relative px-4 py-1 mt-8">
            + {t("addPhotos")}
            <input
              className="absolute top-0 left-0 w-full opacity-0 cursor-pointer"
              type="file"
              accept="image/jpeg, image/png"
              multiple
              onChange={handleFileChange}
            />
          </div>
          <div className="grid grid-cols-3 gap-y-4  w-full mt-4">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="relative w-24 h-24 flex items-center justify-center"
              >
                <img
                  className="h-20 rounded-lg"
                  alt="Uploaded"
                  src={URL.createObjectURL(file)}
                />
                <TiDelete
                  onClick={() =>
                    setSelectedFiles(
                      selectedFiles.filter((_, index2) => index2 !== index)
                    )
                  }
                  color="red"
                  size="26"
                  className="absolute top-0 right-0  cursor-pointer"
                />
              </div>
            ))}
          </div>
          {selectedFiles.length > 0 && (
            <button
              className="bg-primary-light text-white font-bold py-2 px-4 rounded mt-4"
              onClick={handleFilesUpload}
            >
              {t("save")}
            </button>
          )}
        </div>
      )}
    </>
  );
}
