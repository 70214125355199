import React, { useState, useEffect } from "react";

import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/Auth";
import { checkIfFormFilledAndGetData } from "api/profile";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";

export const AuthenticatedOnlyRoute = ({
  children,
  allowedIfFormNotFilled,
}) => {
  const userData = useSelector((state) => state.userData);
  const { user } = useAuth();
  const [formData, setFormData] = useState(null);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const data = await checkIfFormFilledAndGetData(userData.data.id);
        if (data.media_data) {
          localStorage.setItem("userFirstImage", data.media_data[0].name);
        } else {
          localStorage.setItem("userFirstImage", "null");
        }
        setFormData(data);
        setIsLoading(false);
      } catch (error) {
        // Handle error, e.g., log it or show an error message
        console.error("Error fetching data:", error);
      }
    };
    if (user) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [location]);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <BarLoader color="#f43f5e" width={300} />
      </div>
    );
  } else {
    if (!user) {
      // user is not authenticated
      return <Navigate to="/login" />;
    } else {
      if (
        formData.isTextFormNull === true ||
        formData.has_paid === false ||
        formData.isMediaNull === true
      ) {
        if (allowedIfFormNotFilled) {
          return React.Children.map(children, (child) =>
            React.cloneElement(child, { formData: formData })
          );
        } else {
          return <Navigate to="/profile_page_setup" />;
        }
      } else {
        return React.Children.map(children, (child) =>
          React.cloneElement(child, { formData: formData })
        );
      }
    }
  }

  // if (!user) {
  //   // user is not authenticated
  //   return <Navigate to="/login" />;
  // } else {
  //   // Check if formData is still null (loading) or has data
  //   if (formData === null) {
  //     // You can return a loading indicator or null
  //     return (
  //       <div className="flex justify-center">
  //         <BarLoader color="#f43f5e" width={300} />
  //       </div>
  //     );
  //   } else {
  //     if (
  //       formData.isTextFormNull === true ||
  //       formData.has_paid === false ||
  //       formData.isMediaNull === true
  //     ) {
  //       if (allowedIfFormNotFilled) {
  //         return <>{children}</>;
  //       } else {
  //         return <Navigate to="/profile_page_setup" />;
  //       }
  //     } else {
  //       return <>{children}</>;
  //     }
  //   }
  // }

  // Render children with the fetched data
};

export const UnAuthenticatedOnlyRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <>{children}</>;
  } else {
    return <Navigate to="/home" />;
  }
};
