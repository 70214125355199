import { supabase } from "supabase/supabaseClient";
import { sendLogToServer } from "./logging";

export const getFollowersFollowingOfUser = async (user_id) => {
  sendLogToServer("getFollowersFollowingOfUser");
  try {
    let { data: following_followers_data, error4 } = await supabase
      .from("follows")
      .select("*")
      .or(`follower_id.eq.${user_id},following_id.eq.${user_id}`);

    return following_followers_data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error getFollowersFollowingOfUser: ${error}`);
  }
};

export const checkFollowerFollowing = async (
  follower_user_id,
  following_user_id
) => {
  sendLogToServer("checkFollowerFollowing");
  try {
    let { data: follow_data, error } = await supabase
      .from("follows")
      .select("*")
      .eq("follower_id", follower_user_id)
      .eq("following_id", following_user_id);

    return follow_data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error checkFollowerFollowing: ${error}`);
  }
};

export const followUser = async (follower_id, following_id) => {
  sendLogToServer("followUser");
  try {
    const { data, error } = await supabase
      .from("follows")
      .insert([{ follower_id: follower_id, following_id: following_id }]);

    return data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error followUser: ${error}`);
  }
};

export const unFollowUser = async (follower_id, following_id) => {
  sendLogToServer("unFollowUser");
  try {
    const { data, error } = await supabase
      .from("follows")
      .delete()
      .eq("follower_id", follower_id)
      .eq("following_id", following_id);
    return data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error unFollowUser: ${error}`);
  }
};

export const getFollowing = async (user_id, from, to) => {
  sendLogToServer("getFollowing");

  try {
    const { data: following_data, error2 } = await supabase
      .from("follows_view")
      .select("*")
      .eq("follower_id", user_id)
      .range(from, to - 1);

    return following_data;
  } catch (error) {
    sendLogToServer("Error getFollowing fetching session:", error);
  }
};
