import React from "react";
import privacy_policy from "utility/privacy_policy";
import { NavbarComponent2 } from "components/NavBar";

export default function PrivacyPolicyPage() {
  return (
    <div className="w-[100%] h-[100vh]">
      <div className="h-[10%]">
        <NavbarComponent2 />
      </div>
      <div className="h-[90%] overflow-y-auto w-full px-7">
        {privacy_policy()}
      </div>
    </div>
  );
}
