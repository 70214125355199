import React, { useEffect, useState } from "react";
import { getPaymentOrder, verifyPaymentSignature } from "api/edgeFunctions";
import { useSelector } from "react-redux";
import { addUserPayment } from "api/payment";
import { getUserPayment } from "api/payment";
import Loader from "components/Loader";
import { formatDate } from "utility/functions";
import { sendLogToServer } from "api/logging";
import { useTranslation } from "react-i18next";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

export default function PaymentForm(props) {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);

  const [isUserPaid, setIsUserPaid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const [paymentUpdateTrigger, setPaymentUpdateTrigger] = useState(false);

  useEffect(() => {
    // Dynamically load the Razorpay script so it's available when the component mounts
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    getUserPayment(userData.data.id).then((payment) => {
      if (payment.payment_data.length > 0) {
        if (new Date(payment.payment_data[0].expiry_at) > new Date()) {
          setIsUserPaid(true);
          setPaymentData(payment.payment_data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  }, [userData.data.id, paymentUpdateTrigger]);

  function handleButtonClick() {
    props.setIsLoading(true);
    getPaymentOrder(userData.data.id).then((order) => {
      if (order === undefined) {
        setPaymentFailed(true);
        props.setIsLoading(false);
      } else {
        handlePayment(
          order.id,
          order.amount,
          order.currency,
          order.notes.reciept_id
        );
      }
    });
  }

  // function to get next year date
  function getNextYearDate() {
    if (isUserPaid) {
      var date2 = new Date(paymentData[0].expiry_at);

      date2.setFullYear(date2.getFullYear() + 1);
      return date2;
    } else {
      var date = new Date();

      date.setFullYear(date.getFullYear() + 1);
      return date;
    }
  }

  const handlePayment = (order_id, amount, currency, reciept_id) => {
    const options = {
      key: RAZORPAY_KEY_ID, // Enter your Key ID
      amount: amount, // Amount is in currency subunits
      currency: currency,
      name: "Teli Vadhu Var Parichay",
      description: "Transaction",
      image: `${SUPABASE_URL}/storage/v1/object/public/avatars/assets/matrimony_logo.png`,
      order_id: order_id, // Pass the id obtained in the response of Step 1
      handler: function (response) {
        verifyPaymentSignature(response).then((res) => {
          if (res.success) {
            sendLogToServer("Payment Successful");
            addUserPayment({
              user_id: userData.data.id,
              payment_id: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              signature: response.razorpay_signature,
              reciept_id: reciept_id,
              amount: amount,
              currency: currency,
              expiry_at: getNextYearDate(),
            }).then(() => {
              setPaymentFailed(false);

              setPaymentUpdateTrigger((prev) => !prev);
              props.setIsLoading(false);
            });
          } else {
            setPaymentFailed(true);
            props.setIsLoading(false);
          }
        });
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#f43f5e",
      },
      modal: {
        ondismiss: function () {
          props.setIsLoading(false);
        },
      },
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      setPaymentFailed(true);
      sendLogToServer(response);
      props.setIsLoading(false);
    });
    rzp1.open();
  };

  return (
    <div className="w-full">
      {loading ? (
        <div className="w-full flex flex-col items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {paymentFailed ? (
            <div
              className="bg-red-100 border mb-4 border-red-400 text-red-700 px-4 py-1 rounded flex items-center justify-between"
              role="alert"
            >
              <strong className="font-semibold">{t("payment_failed")}</strong>
              <span
                onClick={() => {
                  setPaymentFailed(false);
                }}
                className=""
              >
                <svg
                  className="fill-current h-4 w-4 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>{t("close")}</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          ) : null}
          <div className="w-full flex flex-col items-center justify-center">
            <h1 className="text-2xl font-bold text-black text-center">
              {isUserPaid ? t("paid") : t("pay")}
            </h1>
            <p className="text-black mt-3 text-center">
              {isUserPaid
                ? `${t("payment_description2")} ${formatDate(
                    paymentData[0].expiry_at
                  )}`
                : t("payment_description")}
            </p>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            <button
              id="rzp-button1"
              onClick={() => {
                handleButtonClick();
              }}
              className="bg-primary-light mt-3 text-white text-center px-4 py-2 rounded-md"
            >
              {isUserPaid ? t("renew") : t("pay")}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
