import { useState, useEffect } from "react";
import Loader from "./Loader";
import { useForm } from "react-hook-form";
import {
  getDistinctCaste,
  getDistinctEducation,
  getDistinctCompanyRole,
  getDistinctCity,
} from "api/filters";
import { MultiDropdownSearchableComponent } from "./MultiDropdownSearchableComponent";
import { DropdownSearchableComponent } from "./DropdownSearchableComponent";

export default function FilterPanel(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [filtersData, setFiltersData2] = useState({
    highest_education: [],
    caste: [],
    company_role: [],
    city: [],
  });

  function convertData(inputData, keyPrefix = "", propertyName) {
    return inputData.map((item, index) => ({
      key: `${keyPrefix}_${index}`,
      value: item[propertyName],
      text: item[propertyName],
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [education_data, caste_data, company_role_data, city_data] =
          await Promise.all([
            getDistinctEducation(),
            getDistinctCaste(),
            getDistinctCompanyRole(),
            getDistinctCity(),
          ]);
        const combined_data = {
          highest_education: convertData(
            education_data,
            "education_data",
            "highest_education"
          ),
          caste: convertData(caste_data, "caste_data", "caste"),
          company_role: convertData(
            company_role_data,
            "company_role_data",
            "company_role"
          ),
          city: convertData(city_data, "city_data", "city"),
        };

        setFiltersData2(combined_data);
        // Process data...
      } catch (error) {
        // Handle errors...
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const { handleSubmit, setValue, getValues } = useForm({
    defaultValues: props.filtersData,
  });

  const onFilterDetailsSubmit = async (filter_data) => {
    props.setProfiles([]);
    props.setNoMoreResults(false);
    props.setFiltersData(filter_data);
    props.setFilterOpen(false);
  };

  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onFilterDetailsSubmit)}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label="Gender"
                    name="gender"
                    placeholder="Select Gender"
                    options={[
                      { key: "m", value: "Male", text: "Male" },
                      { key: "f", value: "Female", text: "Female" },
                    ]}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <MultiDropdownSearchableComponent
                    label="Education"
                    name="highest_education"
                    placeholder="Select Education"
                    options={filtersData.highest_education}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <MultiDropdownSearchableComponent
                    label="Job Field"
                    name="company_role"
                    placeholder="Select Job Field"
                    options={filtersData.company_role}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>
              {/* <h2 className="text-base font-semibold leading-7 text-gray-900 mt-7">
                Minimum Salary (per month) - {incomeRange}
              </h2> */}
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label="Minimum Income (per annum)"
                    name="income"
                    placeholder="Select Income"
                    options={[
                      { key: "0", value: 0, text: "Any" },
                      { key: "1", value: 500000, text: "5 lakh" },
                      { key: "2", value: 1000000, text: "10 lakh" },
                      { key: "3", value: 1500000, text: "15 lakh" },
                      { key: "4", value: 2000000, text: "20 lakh" },
                      { key: "5", value: 2500000, text: "25 lakh" },
                    ]}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <MultiDropdownSearchableComponent
                    label="City"
                    name="city"
                    placeholder="Select City"
                    options={filtersData.city}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <MultiDropdownSearchableComponent
                    label="Caste"
                    name="caste"
                    placeholder="Select Caste"
                    options={filtersData.caste}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              onClick={() => {
                props.setProfiles([]);
                props.setNoMoreResults(false);
                props.setFiltersData({});
                props.setFilterOpen(false);
              }}
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-primary-light shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Clear All
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-light px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
}
