import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import NavbarComponent from "components/NavBar";
import { getAllProfilesFromTo } from "api/profiles";
import "./style.css";
import { debounce } from "lodash";
import FilterPanel from "components/FilterPanel";

import { sendLogToServer } from "api/logging";

import { Filter, X } from "lucide-react";

import UserCard from "helper/HomePageHelper";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { t } = useTranslation();

  const PAGE_COUNT = 6;

  const [offset, setOffset] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [isInfiniteLoading, setInfiniteLoading] = useState(false);

  const [profiles, setProfiles] = useState([]);

  const userData = useSelector((state) => state.userData);

  const containerRef = useRef(null);

  const [noMoreResults, setNoMoreResults] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);

  const [filtersData, setFiltersData] = useState({});

  function removeKeysEmptyList(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        delete obj[key];
      }
    }
    return obj;
  }

  // test deployment 1
  useEffect(() => {
    getAllProfilesFromTo(
      userData.data.id,
      0,
      PAGE_COUNT,
      removeKeysEmptyList(filtersData)
    ).then((all_profiles_data) => {
      if (all_profiles_data) {
        if (all_profiles_data.length !== 0) {
          setProfiles(all_profiles_data);
          setOffset(1);
          setIsLoading(false);
          if (all_profiles_data.length < PAGE_COUNT) {
            setInfiniteLoading(false);
            setNoMoreResults(true);
          }
        } else {
          // No more results
          sendLogToServer("No more results");
          setNoMoreResults(true);
          setInfiniteLoading(false);
        }
      }
    });
  }, [filtersData, userData.data.id]);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = debounce(() => {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      // Check if the user has scrolled to the bottom
      const tolerance = 1;

      // Check if the user has scrolled to the bottom with tolerance
      const atBottom =
        Math.abs(scrollTop + clientHeight - scrollHeight) <= tolerance;

      // Load more data when at the bottom
      if (atBottom === true && noMoreResults === false) {
        loadMoreData();
      }
    }, 300);

    const loadMoreData = async () => {
      sendLogToServer("load more data");
      try {
        setInfiniteLoading(true);
        const from = offset * PAGE_COUNT;
        const to = from + PAGE_COUNT - 1;
        getAllProfilesFromTo(userData.data.id, from, to, filtersData).then(
          (all_profiles_data2) => {
            if (all_profiles_data2.length !== 0) {
              setOffset(offset + 1);
              setProfiles([...profiles, ...all_profiles_data2]);
            } else {
              // No more results
              sendLogToServer("No more results");
              setNoMoreResults(true);
              setInfiniteLoading(false);
            }
          }
        );
      } catch (error) {
        console.error("Error loading more data:", error);
        setInfiniteLoading(false);
      }
    };

    // Attach the scroll event listener to the container
    container.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      container.removeEventListener("scroll", handleScroll);
    };
  }, [noMoreResults, offset, profiles]);

  return (
    <div className="w-[100%] h-[100vh]">
      <div className="h-[10%]">
        <NavbarComponent searchBarOn={true} />
      </div>
      <div className="h-[5%] px-6 text-sm flex flex-row items-center justify-between">
        {t("search_results")}
        <button
          onClick={() => {
            setFilterOpen(!filterOpen);
          }}
          type="button"
          className="gap-x-2 py-2 px-4 font-normal border-2 border-[#FFE0E4] rounded-full flex  justify-center items-center text-sm bg-white text-primary-light "
        >
          <Filter size={16} /> <div className="text-black">Filters</div>
          {Object.keys(filtersData).length === 0 ? (
            <></>
          ) : (
            <div className="w-5 h-5 rounded-full font-semibold bg-[#EF6F81] text-white flex justify-center items-center">
              {Object.keys(filtersData).length}
            </div>
          )}
        </button>
      </div>
      <div ref={containerRef} className="h-[85%] overflow-y-auto w-full px-6">
        {isLoading ? (
          <div className="h-36 items-center justify-center flex">
            <Loader />
          </div>
        ) : (
          <>
            {profiles.map((profile_data, index) => (
              <UserCard key={index} profile_data={profile_data} />
            ))}
            {isInfiniteLoading ? (
              <div className="w-full text-center">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            {noMoreResults ? <div> {t("no_more_results")}</div> : <></>}
          </>
        )}
      </div>

      {filterOpen ? (
        <div
          id="drawer-bottom-example"
          className={`max-w-[725px] mx-auto fixed h-[100%] bottom-0 left-0 right-0 z-40 w-full p-4 overflow-y-auto transition-transform bg-white  transform-none`}
          tabIndex="-1"
          aria-labelledby="drawer-bottom-label"
        >
          <h5
            id="drawer-bottom-label"
            className="inline-flex items-center mb-4 text-base font-semibold text-gray-500"
          >
            <Filter size={18} className="text-primary-light mr-2" />
            Filters
          </h5>
          <button
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
            type="button"
            data-drawer-hide="drawer-bottom-example"
            aria-controls="drawer-bottom-example"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center"
          >
            <X size={18} className="text-primary-light" />
            <span className="sr-only">Close menu</span>
          </button>
          <div className="w-full h-auto ">
            <FilterPanel
              setFiltersData={setFiltersData}
              setFilterOpen={setFilterOpen}
              filtersData={filtersData}
              setProfiles={setProfiles}
              setNoMoreResults={setNoMoreResults}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
