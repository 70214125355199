import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import InputComponent from "components/InputComponent";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { fetchUserPersonalData } from "api/profile";

import { DropdownSearchableComponent } from "components/DropdownSearchableComponent";

import { updateDataOnSumbit } from "./configs";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { SaveButton } from "./configs";

export default function PersonalDetailsForm(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [personalData, setPersonalData] = useState(null);
  const userData = useSelector((state) => state.userData);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: personalData,
  });

  const onPersonalDetailsSubmit = async (personal_data) => {
    updateDataOnSumbit(
      personal_data,
      personalData,
      getValues,
      props.setAccordionActive,
      userData.data.id,
      navigate
    );
  };

  useEffect(() => {
    register("caste", {
      required: true,
    });
    register("gender", {
      required: true,
    });

    register("mangal_status", {
      required: true,
    });
    register("married_status", {
      required: true,
    });
    fetchUserPersonalData(userData.data.id).then((userPRofileData) => {
      setPersonalData(userPRofileData[0]);
      Object.keys(userPRofileData[0]).forEach((key) => {
        setValue(key, userPRofileData[0][key]);
      });
      setIsLoading(false);
    });
  }, [register, setValue, userData.data.id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onPersonalDetailsSubmit)}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label={t("gender")}
                    name="gender"
                    placeholder={t("gender")}
                    options={[
                      { key: "m", value: "Male", text: "Male" },
                      { key: "f", value: "Female", text: "Female" },
                    ]}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputComponent
                    id="phone"
                    label={t("phone")}
                    placeholder={t("phone")}
                    type="number"
                    step=""
                    register={{
                      ...register("phone", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputComponent
                    id="first-name"
                    label={t("firstName")}
                    placeholder={t("firstName")}
                    type="text"
                    step=""
                    register={{
                      ...register("first_name", {
                        required: true,
                      }),
                    }}
                  />
                </div>

                <div className="sm:col-span-3">
                  <InputComponent
                    id="middle-name"
                    label={t("middleName")}
                    placeholder={t("middleName")}
                    type="text"
                    step=""
                    register={{
                      ...register("middle_name", {
                        required: true,
                      }),
                    }}
                  />
                </div>

                <div className="sm:col-span-4">
                  <InputComponent
                    id="last-name"
                    label={t("lastName")}
                    placeholder={t("lastName")}
                    type="text"
                    step=""
                    register={{
                      ...register("last_name", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-4">
                  <InputComponent
                    id="regional_full_name"
                    label={`पूर्ण नाव (मराठी)`}
                    placeholder="पूर्ण नाव (मराठी)"
                    type="text"
                    step=""
                    register={{
                      ...register("regional_full_name", {
                        required: false,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-4">
                  <InputComponent
                    id="birthday"
                    label={t("birthdate")}
                    placeholder={t("birthdate")}
                    type="date"
                    step=""
                    register={{
                      ...register("dob", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-4">
                  <InputComponent
                    id="birthday-time"
                    label={t("birthtime")}
                    placeholder={t("birthtime")}
                    type="time"
                    step="any"
                    register={{
                      ...register("dob_time", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label={t("married")}
                    name="married_status"
                    placeholder={t("married")}
                    options={props.dropdownsData.married_dropdwon_list}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
                <div className="sm:col-span-4">
                  <InputComponent
                    id="linkedin"
                    label={`LinkedIn (Provide Link)`}
                    placeholder="https://www.linkedin.com/in/username"
                    type="text"
                    step=""
                    register={{
                      ...register("linkedin", {
                        required: false,
                        pattern: {
                          value:
                            /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                          message: "Please enter a valid URL",
                        },
                      }),
                    }}
                  />
                  {errors.linkedin && (
                    <div className="text-red-500">
                      {errors.linkedin.message}
                    </div>
                  )}
                </div>
                <div className="sm:col-span-4">
                  <InputComponent
                    id="instagram"
                    label={`Instagram (Provide Link)`}
                    placeholder="https://www.instagram.com/username"
                    type="text"
                    step=""
                    register={{
                      ...register("instagram", {
                        required: false,
                        pattern: {
                          value:
                            /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                          message: "Please enter a valid URL",
                        },
                      }),
                    }}
                  />
                  {errors.instagram && (
                    <div className="text-red-500">
                      {errors.instagram.message}
                    </div>
                  )}
                </div>
                <div className="sm:col-span-4">
                  <InputComponent
                    id="Facebook"
                    label={`facebook (Provide Link)`}
                    placeholder="https://www.facebook.com/username"
                    type="text"
                    step=""
                    register={{
                      ...register("facebook", {
                        required: false,
                        pattern: {
                          value:
                            /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                          message: "Please enter a valid URL",
                        },
                      }),
                    }}
                  />
                  {errors.facebook && (
                    <div className="text-red-500">
                      {errors.facebook.message}
                    </div>
                  )}
                </div>
                <div className="sm:col-span-4">
                  <label
                    htmlFor="expectation"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    {t("expectations")}
                  </label>
                  <textarea
                    id="expectation"
                    placeholder="Write your expectations here..."
                    type="text"
                    rows="2"
                    maxLength="100"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                    style={{ maxHeight: "100px", minHeight: "50px" }}
                    {...register("expectation", {
                      required: true,
                    })}
                  ></textarea>
                </div>
              </div>

              <h2 className="text-base font-semibold leading-7 text-gray-900 mt-7">
                {t("otherDetails")}
              </h2>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <InputComponent
                    id="height"
                    label={t("height")}
                    placeholder={t("height")}
                    type="number"
                    step="any"
                    register={{
                      ...register("height", {
                        required: true,
                      }),
                    }}
                  />
                </div>

                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label={t("residingIn")}
                    name="city"
                    placeholder={t("residingIn")}
                    options={[
                      { key: "pun", value: "Pune", text: "Pune" },
                      { key: "mum", value: "Mumbai", text: "Mumbai" },
                    ]}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>

                <div className="sm:col-span-4">
                  <InputComponent
                    id="disability"
                    label={t("disability")}
                    placeholder={t("disability")}
                    type="text"
                    step="any"
                    register={{
                      ...register("disability", {
                        required: false,
                      }),
                    }}
                  />
                </div>
              </div>

              <h2 className="text-base font-semibold leading-7 text-gray-900 mt-7">
                {t("casteDetails")}
              </h2>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label={t("kul")}
                    name="caste"
                    placeholder={t("kul")}
                    options={[
                      { key: "af", value: "kul", text: "kul" },
                      { key: "ax", value: "tul", text: "tul" },
                      { key: "al", value: "nul", text: "nul" },
                    ]}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <DropdownSearchableComponent
                    label={t("mangal")}
                    name="mangal_status"
                    placeholder={t("mangal")}
                    options={props.dropdownsData.mangal_dropdwon_list}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <SaveButton name={t("save")} />
          </div>
        </form>
      )}
    </>
  );
}
