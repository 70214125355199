import { sendLogToServer } from "./logging";
import { supabase } from "supabase/supabaseClient";

export const getAllProfilesFromTo = async (user_id, from, to, filter_data) => {
  sendLogToServer("getAllProfiles");
  try {
    const { gender, income, highest_education, city, caste, company_role } =
      filter_data;

    const numericIncome = parseInt(income, 10);

    let query = supabase
      .from("valid_profiles")
      .select("*")
      .neq("id", user_id)
      .range(from, to - 1);

    if (caste) {
      query = query.in("caste", caste);
    }
    if (city) {
      query = query.in("city", city);
    }
    if (company_role) {
      query = query.in("company_role", company_role);
    }
    if (gender) {
      query = query.eq("gender", gender);
    }
    if (highest_education) {
      query = query.in("highest_education", highest_education);
    }

    if (numericIncome > 0) {
      query = query.gte("income", numericIncome);
    }

    const { data, error } = await query;

    return data;
  } catch (error) {
    sendLogToServer("Error getAllProfiles fetching session:", error);
  }
};

export function calculateAge(birthDate) {
  // Parse the birth date string to a Date object
  const dob = new Date(birthDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds between the current date and birth date
  const timeDiff = currentDate - dob;

  // Calculate the age by dividing the time difference by the number of milliseconds in a year
  const age = Math.floor(timeDiff / (365.25 * 24 * 60 * 60 * 1000));

  return age;
}

export const searchOtherUsers = async (user_id, searchQuery) => {
  sendLogToServer("searchOtherUsers");
  const intSearchQuery = Number(searchQuery); // Use Number() for better handling

  let queryString;

  if (!isNaN(intSearchQuery)) {
    // If intSearchQuery is a valid number
    queryString = `user_code.eq.${intSearchQuery}`;
  } else {
    // If intSearchQuery is not a valid number (treat it as a string)
    queryString = `first_name.ilike.*${searchQuery}*,middle_name.ilike.*${searchQuery}*,last_name.ilike.*${searchQuery}*`;
  }

  try {
    const { data, error } = await supabase
      .from("valid_profiles")
      .select("*")
      .or(queryString)
      .neq("id", user_id)
      .limit(5);
    return data;
  } catch (error) {
    console.error("Error fetching session:", error);
  }
};
