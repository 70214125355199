import React from "react";
import terms_and_conditions from "utility/terms_and_conditions";
import { NavbarComponent2 } from "components/NavBar";

export default function TermsAndConditionsPage() {
  return (
    <div className="w-[100%] h-[100vh]">
      <div className="h-[10%]">
        <NavbarComponent2 />
      </div>
      <div className="h-[90%] overflow-y-auto w-full px-7">
        {terms_and_conditions()}
      </div>
    </div>
  );
}
