import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Cake, Briefcase, IndianRupee, ScrollText } from "lucide-react";
import { calculateAge } from "api/profiles";
import Loader from "components/Loader";
import { getSingleMediaUrl } from "api/profile";
import { formatDate } from "utility/functions";

export default function UserCard({ profile_data }) {
  const navigate = useNavigate();

  const [imageLoading, setImageLoading] = useState(true); // State to manage loading
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    getSingleMediaUrl(profile_data.id, profile_data.gender).then(
      (media_url) => {
        setImageSrc(media_url);
        setImageLoading(false);
      }
    );
  }, [profile_data.id]);

  return (
    <div
      onClick={() => {
        navigate("/user_profile_page", {
          state: {
            user_id: profile_data.id,
          },
        });
      }}
      className="max-w-sm mx-auto cursor-pointer bg-white border border-gray-200 rounded-lg mb-3"
    >
      <div className="flex flex-col h-full">
        <div className="flex-grow p-3">
          <div className="flex justify-between items-start">
            <header>
              <div className="flex mb-2">
                <div className="relative inline-flex items-start mr-5">
                  {imageLoading ? (
                    <div className="w-[78px] h-[78px] rounded-xl flex justify-center items-center">
                      {/* Replace the next line with your actual loader component or element */}
                      <Loader />
                    </div>
                  ) : (
                    <img
                      alt="logo"
                      className="rounded-xl w-[78px] h-[78px] object-cover"
                      src={imageSrc || "https://via.placeholder.com/68x68"} // Fallback to placeholder if no image source
                    />
                  )}
                </div>
                <div className="mt-1 pr-1 flex justify-between flex-col">
                  <h2 className="text-base leading-snug justify-center font-semibold">
                    {profile_data.first_name} {profile_data.middle_name}{" "}
                    {profile_data.last_name}
                  </h2>
                  <div className="  inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent    disabled:opacity-50 disabled:pointer-events-none ">
                    {profile_data.highest_education}
                  </div>
                  <div className="w-full flex flex-row flex-wrap">
                    <div className=" bg-[#FFC8D5] mr-1 px-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#984156]">
                      {profile_data.user_code}
                    </div>
                    <div className="text-sm mr-2">
                      | {`${calculateAge(profile_data.dob)} years`}
                    </div>
                    <div className="text-sm mr-2">| {profile_data.city}</div>
                    <div className="text-sm ">| {profile_data.height}</div>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div className="mt-1 w-full">
            <div className="grid grid-cols-2 gap-x-1 gap-y-[6px]">
              <div className="  inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent    disabled:opacity-50 disabled:pointer-events-none ">
                <Cake size={18} className="" /> {formatDate(profile_data.dob)}
              </div>
              <div className="  inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent    disabled:opacity-50 disabled:pointer-events-none ">
                <ScrollText size={18} className="" />
                Kul: {profile_data.caste}
              </div>

              <div className="col-span-2  inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent    disabled:opacity-50 disabled:pointer-events-none ">
                <Briefcase size={18} className="" />
                {profile_data.company_role} at {profile_data.company_name},{" "}
                {profile_data.company_city}
              </div>

              <div className="  inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent    disabled:opacity-50 disabled:pointer-events-none ">
                <IndianRupee size={18} className="" /> {profile_data.income}/
                per month
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function UserCardSearch({ profile_data }) {
  const navigate = useNavigate();

  const [imageLoading, setImageLoading] = useState(true); // State to manage loading
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    getSingleMediaUrl(profile_data.id, profile_data.gender).then(
      (media_url) => {
        setImageSrc(media_url);
        setImageLoading(false);
      }
    );
  }, [profile_data.id]);

  return (
    <div
      onClick={() => {
        navigate("/user_profile_page", {
          state: {
            user_id: profile_data.id,
          },
        });
      }}
      className="max-w-sm mx-auto bg-[#F5F5F5] mb-2 cursor-pointer  border border-gray-200 rounded-lg "
    >
      <div className="flex flex-col h-full">
        <div className="flex-grow p-1">
          <div className="flex justify-between items-start">
            <header>
              <div className="flex ">
                <a className="relative inline-flex items-start mr-5" href="#0">
                  {imageLoading ? (
                    <div className="w-[60px] h-[60px] rounded-xl flex justify-center items-center">
                      {/* Replace the next line with your actual loader component or element */}
                      <Loader />
                    </div>
                  ) : (
                    <img
                      alt="logo"
                      className="rounded-xl w-[60px] h-[60px] object-cover"
                      src={imageSrc || "https://via.placeholder.com/68x68"} // Fallback to placeholder if no image source
                    />
                  )}
                </a>
                <div className="mt-1 pr-1 flex justify-around flex-col">
                  <h2 className="text-base leading-snug justify-center font-semibold">
                    {profile_data.first_name} {profile_data.middle_name}{" "}
                    {profile_data.last_name}
                  </h2>
                  {/* <div className="  inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent    disabled:opacity-50 disabled:pointer-events-none ">
                    {profile_data.highest_education}
                  </div> */}
                  <div className="w-full flex flex-row flex-wrap">
                    <div className=" bg-[#FFC8D5] mr-1 px-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-[#984156]">
                      {profile_data.user_code}
                    </div>
                    <div className="text-sm mr-2">
                      | {`${calculateAge(profile_data.dob)} years`}
                    </div>
                    <div className="text-sm mr-2">| {profile_data.city}</div>
                    <div className="text-sm ">| {profile_data.height}</div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
}
