import React, { useContext, useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { setUserData } from "../redux/slice/user";
import { useDispatch } from "react-redux";
import { sendLogToServer } from "api/logging";

// create a context for authentication
const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setData = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) throw error;
      setSession(session);
      setUser(session?.user);
      setLoading(false);
    };

    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        sendLogToServer("USER SESSION CHECK");
        setSession(session);
        setUser(session?.user);
        dispatch(
          setUserData({
            id: session?.user.id,
          })
        );
        setLoading(false);

        // if (session?.user) {
        //   // Assuming you have a Supabase client instance initialized
        //   supabase
        //     .from("profiles") // Your table name
        //     .select("gender") // Selecting the gender field
        //     .eq("id", session.user.id) // Matching the user ID
        //     .single() // Assuming one profile per user
        //     .then(({ data, error }) => {
        //       if (data) {
        //         // Dispatch or set state with combined user and profile data
        //         dispatch(
        //           setUserData({
        //             id: session.user.id,
        //             gender: data.gender, // gender from profiles table
        //           })
        //         );
        //         setLoading(false);
        //       }
        //       if (error) {
        //         sendLogToServer("Error fetching profile data:", error);
        //         // Handle error
        //       }
        //     });
        // }
      }
    );

    setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, [dispatch]);

  const value = {
    session,
    user,
  };

  // use a provider to pass down the value
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// export the useAuth hook
export const useAuth = () => {
  return useContext(AuthContext);
};

// const AuthContext = React.createContext();

// export function AuthProvider({ children }) {
//   const [user, setUser] = useState();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Check active sessions and sets the user
//     const session = supabase.auth.getSession();
//     setUser(session?.user ?? null);
//     setLoading(false);

//     // Listen for changes on auth state (logged in, signed out, etc.)
//     const { data: listener } = supabase.auth.onAuthStateChange(
//       async (event, session) => {
//         setUser(session?.user ?? null);
//         setLoading(false);
//       }
//     );

//     // return () => {
//     //   listener?.unsubscribe();
//     // };
//   }, []);

//   // Will be passed down to Signup, Login and Dashboard components
//   const value = {
//     signUp: (data) => supabase.auth.signUp(data),
//     signIn: (data) => supabase.auth.signIn(data),
//     signOut: () => supabase.auth.signOut(),
//     user,
//   };

//   return (
//     <AuthContext.Provider value={value}>
//       {!loading && children}
//     </AuthContext.Provider>
//   );
// }

// export function useAuth() {
//   return useContext(AuthContext);
// }
