import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import NavbarComponent from "components/NavBar";
import "./style.css";
import { debounce } from "lodash";
import { getFollowing } from "api/follows";
import { sendLogToServer } from "api/logging";
import UserCard from "helper/HomePageHelper";
import { useTranslation } from "react-i18next";

export default function ShortListedProfilesPage() {
  const { t } = useTranslation();

  const PAGE_COUNT = 20;

  const [offset, setOffset] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [isInfiniteLoading, setInfiniteLoading] = useState(true);

  const [profiles, setProfiles] = useState([]);

  const userData = useSelector((state) => state.userData);

  const containerRef = useRef(null);

  const [noMoreResults, setNoMoreResults] = useState(false);

  useEffect(() => {
    getFollowing(userData.data.id, 0, PAGE_COUNT).then((all_profiles_data) => {
      setProfiles(all_profiles_data);
      if (all_profiles_data.length === 0) {
        setInfiniteLoading(false);
        setNoMoreResults(true);
      } else if (all_profiles_data.length <= 20) {
        setInfiniteLoading(false);
        setNoMoreResults(true);
      }
      setIsLoading(false);
    });
  }, [userData.data.id]);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = debounce(() => {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      // Check if the user has scrolled to the bottom
      const tolerance = 1;

      // Check if the user has scrolled to the bottom with tolerance
      const atBottom =
        Math.abs(scrollTop + clientHeight - scrollHeight) <= tolerance;

      // Load more data when at the bottom
      if (atBottom === true && noMoreResults === false) {
        loadMoreData();
      }
    }, 300);

    const loadMoreData = async () => {
      sendLogToServer("load more data");
      try {
        setInfiniteLoading(true);

        const from = offset * PAGE_COUNT;
        const to = from + PAGE_COUNT - 1;

        getFollowing(userData.data.id, from, to).then((following_data) => {
          if (following_data.length !== 0) {
            setOffset(offset + 1);
            setProfiles([...profiles, ...following_data]);
          } else {
            // No more results
            sendLogToServer("No more results");
            setNoMoreResults(true);
            setInfiniteLoading(false);
          }
        });
      } catch (error) {
        console.error("Error loading more data:", error);
        setInfiniteLoading(false);
      }
    };

    // Attach the scroll event listener to the container
    container.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      container.removeEventListener("scroll", handleScroll);
    };
  }, [noMoreResults, offset, profiles]);
  return (
    <div className="w-[100%] h-[100vh]">
      <div className="h-[10%]">
        <NavbarComponent
          searchBarOn={false}
          textInsteadSearchbar={t("shortListedProfiles")}
        />
      </div>
      <div ref={containerRef} className="h-[90%] overflow-y-auto w-full px-6">
        {isLoading ? (
          <div className="h-36 items-center justify-center flex">
            <Loader />
          </div>
        ) : (
          <>
            {profiles.map((profile_data, index) => (
              <UserCard key={index} profile_data={profile_data} />
            ))}
            {isInfiniteLoading ? (
              <div className="w-full text-center">
                <Loader />
              </div>
            ) : (
              <></>
            )}
            {noMoreResults ? <div>No More results</div> : <></>}
          </>
        )}
      </div>
    </div>
  );
}
