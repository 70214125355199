import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

export default function ImageSliderComponent({
  myProfileMediaData,
  myProfileData,
}) {
  const [openFullScreen, setOpenFullScreen] = useState(false);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} rounded-full flex justify-center items-center`}
        style={{ ...style, display: "block", background: "#f43f5e" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} rounded-full flex justify-center items-center`}
        style={{ ...style, display: "block", background: "#f43f5e" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <div className="w-[334px] h-[372px]">
        <Slider {...settings}>
          {myProfileMediaData?.map((media) => (
            <img
              onClick={() => {
                setOpenFullScreen(!openFullScreen);
              }}
              key={media}
              className="object-contain w-[334px] h-[372px] cursor-pointer"
              alt="Loading"
              src={`${SUPABASE_URL}/storage/v1/object/public/avatars/${myProfileData.id}/${media.name}`}
            />
          ))}
        </Slider>
      </div>
      {openFullScreen ? (
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative p-4 w-full max-w-2xl h-full">
            <div className="relative bg-white rounded-lg shadow h-full">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <button
                  onClick={() => {
                    setOpenFullScreen(!openFullScreen);
                  }}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                  data-modal-hide="default-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <Slider {...settings3}>
                {myProfileMediaData.map((media, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center w-full h-full"
                  >
                    <TransformWrapper
                      defaultScale={1}
                      defaultPositionX={0}
                      defaultPositionY={0}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <div className="flex justify-center items-center w-full h-full">
                            <TransformComponent>
                              <img
                                src={`${SUPABASE_URL}/storage/v1/object/public/avatars/${myProfileData.id}/${media.name}`}
                                alt="Loading"
                                className="max-w-full max-h-full object-contain"
                                style={{ width: "100%", height: "auto" }}
                              />
                            </TransformComponent>
                          </div>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
