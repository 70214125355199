import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import InputComponent from "components/InputComponent";
import { useSelector } from "react-redux";
import Loader from "components/Loader";

import { fetchUserParentData } from "api/profile";

import { parent_details_list } from "./configs";

import { updateDataOnSumbit } from "./configs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SaveButton } from "./configs";

export default function ParentsDetailsForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [educationalData, setEducationalData] = useState(null);
  const userData = useSelector((state) => state.userData);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: educationalData,
  });

  const onPersonalDetailsSubmit = async (personal_data) => {
    updateDataOnSumbit(
      personal_data,
      educationalData,
      getValues,
      props.setAccordionActive,
      userData.data.id,
      navigate
    );
  };

  useEffect(() => {
    fetchUserParentData(userData.data.id).then((userPRofileData) => {
      setEducationalData(userPRofileData[0]);
      Object.keys(userPRofileData[0]).forEach((key) => {
        setValue(key, userPRofileData[0][key]);
      });
      setIsLoading(false);
    });
  }, [register, setValue, userData.data.id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onPersonalDetailsSubmit)}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {parent_details_list(t).map((value, index) => (
                  <div key={value.name} className="sm:col-span-3">
                    <InputComponent
                      id={value.name}
                      label={value.label}
                      placeholder={value.placeholder}
                      type={value.type}
                      step=""
                      register={{
                        ...register(value.name, {
                          required: true,
                        }),
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <SaveButton name={t("save")} />
          </div>
        </form>
      )}
    </>
  );
}
