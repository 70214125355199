import PulseLoader from "react-spinners/PulseLoader";
import { useState } from "react";

function Loader() {
  return (
    <PulseLoader
      color="#f43f5e"
      loading={true}
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}
export default Loader;
