import { useNavigate } from "react-router-dom";
import { sendLogToServer } from "./logging";
import { supabase } from "supabase/supabaseClient";

export const loginWithEmailOTP = async (email) => {
  sendLogToServer("Login with google Started");
  await supabase.auth
    .signInWithOtp({
      email: email,
    })
    .then(() => {
      sendLogToServer("Login successful");
    });
};

export const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    sendLogToServer("Logout");
    await supabase.auth.signOut();
    navigate("/");
  };

  return (
    <button
      onClick={handleLogout}
      className="w-72 h-14  bg-rose-500 rounded-2xl border border-zinc-100 text-center text-white mt-8"
    >
      Log out
    </button>
  );
};

export const fetchUser = async () => {
  sendLogToServer("Fetching User");
  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    return {
      id: user.id,
    };
  } catch (error) {
    sendLogToServer(`Error fetching session:${error}`);
    return error;
  }
};
