import React from "react";

export default function InputComponent({
  id,
  label,
  placeholder,
  type,
  step,
  register,
  setInputValue,
}) {
  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium mb-2">
        {label}
      </label>
      <input
        id={id}
        placeholder={placeholder}
        type={type}
        step={step}
        className="py-3 px-4 block w-full border-[1px] border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none  "
        {...register}
        onInput={
          setInputValue
            ? (e) => {
                setInputValue(e.target.value);
              }
            : undefined
        }
        onWheel={(e) => e.target.blur()}
      />
    </>
  );
}
