import React from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "components/NavBar";

import UserProfileDataUI from "helper/UserProfilePageHelper";

import ImageSliderComponent from "components/ImageSliderComponent";
import { useTranslation } from "react-i18next";

export default function MyProfile({ formData }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const myProfileData = formData.data_without_updated_at;
  const myProfileMediaData = formData.media_data;

  return (
    // <div className="w-full flex justify-center items-center px-8 py-4 mb-10 flex-col">
    <div className="w-[100%] h-[100vh] flex flex-col">
      <div className="h-[10%]">
        <NavbarComponent
          searchBarOn={false}
          textInsteadSearchbar={t("your_profile")}
        />
      </div>

      <div className="h-[90%] overflow-y-auto w-full px-7 flex justify-center">
        <div className="w-[338px]  flex-col justify-start items-center gap-[31px] ">
          <div className="flex-col justify-start items-center flex">
            <ImageSliderComponent
              myProfileMediaData={myProfileMediaData}
              myProfileData={myProfileData}
            />

            <div className="w-full mt-10  flex-col justify-start items-center gap-1.5 flex">
              <div className="text-black  font-medium">
                {myProfileData.user_code}
              </div>
              <div className="text-black text-[22px] font-medium font-['Poppins']">
                {myProfileData.first_name} {myProfileData.middle_name}{" "}
                {myProfileData.last_name}
              </div>
              <div className="text-black text-opacity-70 text-sm font-normal font-['Poppins'] ">
                {myProfileData.company_role} at {myProfileData.company_name}{" "}
                {myProfileData.company_city}
              </div>
              <div className="justify-start items-start gap-5 inline-flex">
                <div
                  onClick={() => {
                    navigate("/profile_page_setup");
                  }}
                  className="w-[46px] h-[46px] relative cursor-pointer"
                >
                  <div className="w-[46px] h-[46px] left-0 top-0 absolute bg-rose-500 rounded-full"></div>
                  <div className="w-[18px] h-[18px] left-[14px] top-[14px] absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 512 512"
                      id="pencil"
                      fill="white"
                    >
                      <path
                        stroke="white"
                        d="M178.846 92.087h128.085v354.049H178.846z"
                        transform="rotate(-134.999 242.89 269.113)"
                      ></path>
                      <path d="M471.723 88.393l-48.115-48.114c-11.723-11.724-31.558-10.896-44.304 1.85l-45.202 45.203 90.569 90.568 45.202-45.202c12.743-12.746 13.572-32.582 1.85-44.305zM64.021 363.252L32 480l116.737-32.021z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfileDataUI myProfileData={myProfileData} />
        </div>
      </div>
    </div>
  );
}
