import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "components/NavBar";

import { checkIfFormFilledAndGetData } from "api/profile";
import { useLocation } from "react-router-dom";

import { followUser, unFollowUser } from "api/follows";

import { checkIfFollower } from "helper/UserProfilePageHelper";

import { Phone } from "lucide-react";

import { BookmarkSimple } from "@phosphor-icons/react";
import UserProfileDataUI from "helper/UserProfilePageHelper";
import ImageSliderComponent from "components/ImageSliderComponent";
import { useTranslation } from "react-i18next";

export default function UserProfilePage() {
  const { t } = useTranslation();

  const userData = useSelector((state) => state.userData);

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [myProfileData, setMyProfileData] = useState(null);
  const [myProfileMediaData, setMyProfileMediaData] = useState(null);
  const [IsFollower, setIsFollower] = useState(null);

  useEffect(() => {
    const { user_id } = location.state || {};

    if (user_id) {
      checkIfFormFilledAndGetData(user_id).then((userPRofileData) => {
        setMyProfileData(userPRofileData.data_without_updated_at);
        setMyProfileMediaData(userPRofileData.media_data);
        checkIfFollower(userData.data.id, user_id, setIsFollower, setIsLoading);
      });
    } else {
      navigate("/home");
    }
  }, [location.state, navigate, userData.data.id]);

  const followClick = async (follower_id, following_id) => {
    followUser(follower_id, following_id);
  };

  const unFollowClick = async (follower_id, following_id) => {
    unFollowUser(follower_id, following_id);
  };

  return (
    <div className="w-[100%] h-[100vh] flex flex-col">
      <div className="h-[10%]">
        <NavbarComponent
          searchBarOn={false}
          // textInsteadSearchbar={t("manage_profile")}
        />
      </div>

      {isLoading ? (
        <div className="h-36 items-center justify-center flex">
          <Loader />
        </div>
      ) : (
        <div className="h-[90%] overflow-y-auto w-full px-7 flex justify-center">
          <div className="w-[338px]  flex-col justify-start items-center gap-[31px]">
            <div className="flex-col justify-start items-center gap-[17px] flex">
              <ImageSliderComponent
                myProfileMediaData={myProfileMediaData}
                myProfileData={myProfileData}
              />
              <div className="flex-col mt-10   justify-start items-center gap-1.5 flex">
                <div className="text-black  font-medium">
                  {myProfileData.user_code}
                </div>
                <div className="text-black text-[22px] font-medium font-['Poppins'] leading-[33px]">
                  {myProfileData.first_name} {myProfileData.middle_name}{" "}
                  {myProfileData.last_name}
                </div>
                <div className="text-black text-opacity-70 text-sm font-normal font-['Poppins'] leading-[21px]">
                  {myProfileData.company_role} at {myProfileData.company_name}{" "}
                  {myProfileData.company_city}
                </div>
              </div>
              <div className="justify-start items-start gap-5 inline-flex ">
                <button
                  type="button"
                  className="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent bg-primary-light text-white hover:bg-primary-light disabled:opacity-50 disabled:pointer-events-none "
                  onClick={() => {
                    window.location.href = `tel:${myProfileData.phone}`;
                  }}
                >
                  <Phone size={18} className="text-white" /> Call
                </button>
                {IsFollower ? (
                  <div
                    onClick={() => {
                      unFollowClick(userData.data.id, myProfileData.id);
                      setIsFollower(false);
                    }}
                    type="button"
                    className="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent bg-[#F3F3F3] text-primary-light  disabled:opacity-50 disabled:pointer-events-none "
                  >
                    <BookmarkSimple
                      size={18}
                      weight="fill"
                      className="text-primary-light"
                    />{" "}
                    Unsave
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      followClick(userData.data.id, myProfileData.id);
                      setIsFollower(true);
                    }}
                    type="button"
                    className="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-normal rounded-full border border-transparent bg-[#F3F3F3] text-primary-light  disabled:opacity-50 disabled:pointer-events-none "
                  >
                    <BookmarkSimple size={18} className="text-primary-light" />
                    Save
                  </div>
                )}
              </div>
            </div>
            <UserProfileDataUI myProfileData={myProfileData} />
          </div>
        </div>
      )}
    </div>
  );
}
