import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Main } from "main";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import "i18n";
import { Suspense } from "react";

function App() {
  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense>
            <div className="max-w-[725px] mx-auto">
              <Main />
            </div>
          </Suspense>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
