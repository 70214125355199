import { upadteUserProfileData } from "api/profile";
import { areObjectsEqual } from "utility/functions";

export function parent_details_list(t) {
  return [
    {
      name: "parent_full_name",
      label: t("parentFullName"),
      placeholder: t("parentFullName"),
      type: "text",
    },
    {
      name: "parent_number",
      label: t("parentPhoneNumber"),
      placeholder: t("parentPhoneNumber"),
      type: "number",
    },
    {
      name: "parent_address",
      label: t("parentAddress"),
      placeholder: t("parentAddress"),
      type: "text",
    },
    {
      name: "parent_taluka",
      label: t("parentTaluka"),
      placeholder: t("parentTaluka"),
      type: "text",
    },
    {
      name: "parent_district",
      label: t("parentDistrict"),
      placeholder: t("parentDistrict"),
      type: "text",
    },
    {
      name: "parent_state",
      label: t("parentState"),
      placeholder: t("parentState"),
      type: "text",
    },
    {
      name: "parent_city",
      label: t("parentCity"),
      placeholder: t("parentCity"),
      type: "text",
    },
    {
      name: "parent_occupation",
      label: t("parentOccupation"),
      placeholder: t("parentOccupation"),
      type: "text",
    },
    {
      name: "mama_full_name",
      label: t("mamaFullName"),
      placeholder: t("mamaFullName"),
      type: "text",
    },
    {
      name: "mama_kul",
      label: t("mamaKul"),
      placeholder: t("mamaKul"),
      type: "text",
    },
    {
      name: "mama_phone",
      label: t("mamaPhone"),
      placeholder: t("mamaPhone"),
      type: "number",
    },
  ];
}

export const updateDataOnSumbit = async (
  personal_data,
  previous_data,
  getValues,
  setAccordionActive,
  user_id,
  navigate
) => {
  if (areObjectsEqual(getValues(), previous_data) === false) {
    upadteUserProfileData(personal_data, user_id).then((is_success) => {
      if (is_success) {
        setAccordionActive(null);
        navigate("/profile_page_setup");
      } else {
        alert("Oops something went wrong, Please try again");
      }
    });
  } else {
    alert("No changes made");
  }
};

export const SaveButton = ({ name }) => {
  return (
    <button
      type="submit"
      className="rounded-md bg-primary-light px-3 py-2 text-sm font-semibold text-white shadow-sm"
    >
      {name}
    </button>
  );
};
