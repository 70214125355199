import React from "react";

export default function ButtonComponent({ onClick, name, type }) {
  return (
    <button
      onClick={onClick}
      type={type}
      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-light text-white hover:bg-primary-light disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
    >
      {name}
    </button>
  );
}
