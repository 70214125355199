import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import InputComponent from "components/InputComponent";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { fetchUserProfessionalData } from "api/profile";
import { updateDataOnSumbit } from "./configs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SaveButton } from "./configs";

export default function ProfessionalDetailsForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [educationalData, setEducationalData] = useState(null);
  const userData = useSelector((state) => state.userData);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: educationalData,
  });

  const onPersonalDetailsSubmit = async (personal_data) => {
    updateDataOnSumbit(
      personal_data,
      educationalData,
      getValues,
      props.setAccordionActive,
      userData.data.id,
      navigate
    );
  };

  useEffect(() => {
    fetchUserProfessionalData(userData.data.id).then((userPRofileData) => {
      setEducationalData(userPRofileData[0]);
      Object.keys(userPRofileData[0]).forEach((key) => {
        setValue(key, userPRofileData[0][key]);
      });
      setIsLoading(false);
    });
  }, [register, setValue, userData.data.id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onPersonalDetailsSubmit)}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputComponent
                    id="company_name"
                    label={t("businessWorkplaceName")}
                    placeholder={t("businessWorkplaceName")}
                    type="text"
                    step=""
                    register={{
                      ...register("company_name", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputComponent
                    id="company_role"
                    label={t("designation")}
                    placeholder={t("designation")}
                    type="text"
                    step=""
                    register={{
                      ...register("company_role", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputComponent
                    id="company_city"
                    label={t("city")}
                    placeholder={t("city")}
                    type="text"
                    step=""
                    register={{
                      ...register("company_city", {
                        required: true,
                      }),
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputComponent
                    id="income"
                    label={t("income")}
                    placeholder={t("income")}
                    type="number"
                    step=""
                    register={{
                      ...register("income", {
                        required: true,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <SaveButton name={t("save")} />
          </div>
        </form>
      )}
    </>
  );
}
