export function areObjectsEqual(obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare values
  for (let key of keys1) {
    // Check if the values for the current key are equal
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all checks pass, the objects are equal
  return true;
}

export function formatDate(dateString) {
  // Create a new Date object from the input string
  const date = new Date(dateString);

  // Define an array of month names to convert month from number to word
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day, month (as word), and year from the Date object
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Return the formatted date string
  return `${day} ${month} ${year}`;
}

export const ensureHttpPrefix = (url) => {
  if (!url) return url; // Return as is if null or empty
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url; // URL is already correct
  }
  return `https://${url}`; // Add 'https://' prefix to URLs without it
};
