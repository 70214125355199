import { sendLogToServer } from "./logging";
import { supabase } from "supabase/supabaseClient";

export const getDistinctEducation = async () => {
  sendLogToServer("getDistinctEducation");

  try {
    const { data, error } = await supabase
      .from("distinct_highest_education")
      .select("*");

    return data;
  } catch (error) {
    sendLogToServer("Error getDistinctEducation fetching session:", error);
  }
};

export const getDistinctCaste = async () => {
  sendLogToServer("getDistinctCaste");

  try {
    const { data, error } = await supabase.from("distinct_caste").select("*");

    return data;
  } catch (error) {
    sendLogToServer("Error getDistinctCaste fetching session:", error);
  }
};

export const getDistinctCompanyRole = async () => {
  sendLogToServer("getDistinctCompanyRole");

  try {
    const { data, error } = await supabase
      .from("distinct_company_role")
      .select("*");

    return data;
  } catch (error) {
    sendLogToServer("Error getDistinctCompanyRole fetching session:", error);
  }
};

export const getDistinctCity = async () => {
  sendLogToServer("getDistinctCity");

  try {
    const { data, error } = await supabase.from("distinct_city").select("*");

    return data;
  } catch (error) {
    sendLogToServer("Error getDistinctCity fetching session:", error);
  }
};
