import axios from "axios";
import { sendLogToServer } from "./logging";
import { supabase } from "supabase/supabaseClient";

const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;
const EDGE_FUNCTION_URL_DATA_LIST =
  process.env.REACT_APP_SUPABASE_EDGE_FUNCTION_URL_DATA_LIST;

const EDGE_FUNCTION_PAYMENT_ORDER =
  process.env.REACT_APP_SUPABASE_EDGE_FUNCTION_PAYMENT_ORDER;

const EDGE_FUNCTION_VERIFY_PAYMENT_SIGNATURE =
  process.env.REACT_APP_SUPABASE_EDGE_FUNCTION_VERIFY_PAYMENT;

export const fetchDropdownData = async () => {
  sendLogToServer("edge function hit dropdown data");
  const url = EDGE_FUNCTION_URL_DATA_LIST;
  const token = SUPABASE_KEY;

  const data = {
    name: "Functions",
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getPaymentOrder = async (user_id) => {
  sendLogToServer("edge function hit getPaymentOrder data");
  const url = EDGE_FUNCTION_PAYMENT_ORDER;
  const token = SUPABASE_KEY;

  const reciept_id = `${user_id}-${Math.floor(
    Math.random() * 1000000
  )}-${Date.now()}`;

  const data = {
    name: "Functions",
    amount: 29900,
    // generate a reciept id with user_id and a random number and time
    reciept_id: reciept_id,
    notes: {
      user_id: user_id,
      reciept_id: reciept_id,
    },
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const verifyPaymentSignature = async (response_data) => {
  const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
    response_data;
  sendLogToServer("edge function hit verifyPaymentSignature data");
  const url = EDGE_FUNCTION_VERIFY_PAYMENT_SIGNATURE;
  const token = SUPABASE_KEY;

  const data = {
    name: "Functions",
    razorpay_payment_id: razorpay_payment_id,
    razorpay_order_id: razorpay_order_id,
    razorpay_signature: razorpay_signature,
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const deleteSelfAccount = async (user_id) => {
  sendLogToServer("deleteSelfAccount");
  const url = process.env.REACT_APP_SUPABASE_EDGE_FUNCTION_DELETE_ACCOUNT;
  const token = SUPABASE_KEY;

  const data = {
    name: "Functions",
    user_id: user_id,
  };

  try {
    sendLogToServer("Logout");
    await supabase.auth.signOut();

    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
