import { sendLogToServer } from "./logging";
import { supabase } from "supabase/supabaseClient";
import { v4 as uuidv4 } from "uuid";
import { getUserPayment } from "./payment";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

export const upadteUserProfileData = async (profile_data, user_id) => {
  sendLogToServer("Updating User Personal Data");

  try {
    const { data, error } = await supabase
      .from("profiles")
      .update(profile_data)
      .eq("id", user_id);

    if (error) {
      sendLogToServer("Error updating user data:", error.message);
      return false;
    } else {
      sendLogToServer("User data updated successfully:", data);
      return true;
    }
  } catch (error) {
    sendLogToServer("Error updating user data:", error.message);
    return false;
  }
};

export function hasNullOrEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === null || value === "") {
        return true;
      }
    }
  }
  return false;
}

function hasNullOrEmptyFromList(keys, obj) {
  for (let key of keys) {
    if (obj[key] === null || obj[key] === "") {
      return true;
    }
  }
  return false;
}

export const checkIfFormFilledAndGetData = async (user_id) => {
  sendLogToServer("Fetching User for checkIfFormFilled");
  try {
    let { data: user_profile, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", user_id);

    // Create a new object without the "updated_at" key
    let newObjectWithoutAge = { ...user_profile[0] };
    delete newObjectWithoutAge.updated_at;
    delete newObjectWithoutAge.disability;
    delete newObjectWithoutAge.has_paid;
    delete newObjectWithoutAge.regional_full_name;
    delete newObjectWithoutAge.instagram;
    delete newObjectWithoutAge.facebook;
    delete newObjectWithoutAge.linkedin;

    let media_null = true;
    let media_data_list = null;

    await getMedia(user_id, 6).then((media_data) => {
      if (media_data) {
        if (media_data.length > 0) {
          media_data_list = media_data;
          media_null = false;
        } else {
          media_null = true;
          media_data_list = null;
        }
      } else {
        media_null = true;
        media_data_list = null;
      }
    });

    const final_obj = {
      is_valid: user_profile[0].is_valid,
      has_paid: (await getUserPayment(user_id)).has_paid,
      isTextFormNull: hasNullOrEmpty(newObjectWithoutAge),
      isMediaNull: media_null,
      data_without_updated_at: user_profile[0],
      media_data: media_data_list,
      is_personal_details_null: hasNullOrEmptyFromList(
        [
          "gender",
          "first_name",
          "middle_name",
          "last_name",
          "phone",
          "dob",
          "dob_time",
          "married_status",
          "expectation",
          "height",
          "city",
          "caste",
          "mangal_status",
          "phone",
        ],
        newObjectWithoutAge
      ),
      is_educational_details_null: hasNullOrEmptyFromList(
        ["highest_education", "university"],
        newObjectWithoutAge
      ),
      is_professional_details_null: hasNullOrEmptyFromList(
        ["company_name", "company_role", "company_city", "income"],
        newObjectWithoutAge
      ),
      is_parents_details_null: hasNullOrEmptyFromList(
        [
          "parent_full_name",
          "parent_number",
          "parent_address",
          "parent_taluka",
          "parent_district",
          "parent_state",
          "parent_district",
          "parent_city",
          "parent_occupation",
          "mama_full_name",
          "mama_kul",
          "mama_phone",
        ],
        newObjectWithoutAge
      ),
    };

    final_obj["is_valid"] =
      final_obj.has_paid &&
      !final_obj.isTextFormNull &&
      !final_obj.isMediaNull &&
      !final_obj.is_personal_details_null &&
      !final_obj.is_educational_details_null &&
      !final_obj.is_professional_details_null &&
      !final_obj.is_parents_details_null;

    return final_obj;
  } catch (error) {
    sendLogToServer(`Error fetching session:${error}`);
    await supabase.auth.signOut().then(() => {});
    return error;
  }
};

export const fetchUserPersonalData = async (user_id) => {
  sendLogToServer("Fetching User Personal Data");

  try {
    let { data: userData } = await supabase
      .from("profiles")
      .select(
        "first_name,middle_name,last_name,regional_full_name,gender,phone,dob,dob_time,height,city,caste,expectation,married_status,mangal_status,disability,instagram,facebook,linkedin"
      )
      .eq("id", user_id);

    return userData;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const fetchUserEducationalData = async (user_id) => {
  sendLogToServer("Fetching User Educational Data");

  try {
    let { data: userData } = await supabase
      .from("profiles")
      .select("highest_education,university")
      .eq("id", user_id);

    return userData;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const fetchUserProfessionalData = async (user_id) => {
  sendLogToServer("Fetching User Professional Data");

  try {
    let { data: userData } = await supabase
      .from("profiles")
      .select("company_name,company_role,company_city,income")
      .eq("id", user_id);

    return userData;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const fetchUserParentData = async (user_id) => {
  sendLogToServer("Fetching User Parent Data");

  try {
    let { data: userData } = await supabase
      .from("profiles")
      .select(
        "parent_full_name,parent_number,parent_address,parent_taluka,parent_district,parent_state,parent_city,parent_occupation,mama_full_name,mama_kul,mama_phone"
      )
      .eq("id", user_id);

    return userData;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const uploadMedia = async (user_id, file) => {
  sendLogToServer("upload media sarted");

  try {
    const { data, error } = await supabase.storage
      .from("avatars")
      .upload(user_id + "/" + uuidv4(), file);

    return data;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const getMedia = async (user_id, limit) => {
  sendLogToServer("Fetching User Media Data");

  try {
    const { data, error } = await supabase.storage
      .from("avatars")
      .list(user_id + "/", {
        limit: limit,
        offset: 0,
        sortBy: {
          column: "name",
          order: "asc",
        },
      });
    if (data.length === 0) {
      return null;
    } else {
      return data;
    }
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const getSingleMediaUrl = async (user_id, gender) => {
  sendLogToServer("Fetching Single Media URL");

  try {
    const { data: media_data, error } = await supabase.storage
      .from("avatars")
      .list(user_id + "/", {
        limit: 1, // Limit the query to one image
        offset: 0,
        sortBy: {
          column: "name",
          order: "asc",
        },
      });

    if (error) {
      sendLogToServer("Error fetching session:", error);
      return null;
    }

    if (!media_data || media_data.length === 0) {
      return `${SUPABASE_URL}/storage/v1/object/public/avatars/fake/${gender}/${
        Math.floor(Math.random() * 6) + 1
      }.jpg`;
    }

    return `${SUPABASE_URL}/storage/v1/object/public/avatars/${user_id}/${media_data[0].name}`;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
    return null;
  }
};

// export const getSingleMediaUrl = async (user_id, gender) => {
//   try {
//     const media_data = await getMedia(user_id, 1);

//     if (media_data === null) {
//       return `${SUPABASE_URL}/storage/v1/object/public/avatars/fake/${gender}/${
//         Math.floor(Math.random() * 6) + 1
//       }.jpg`;
//     } else {
//       return `${SUPABASE_URL}/storage/v1/object/public/avatars/${user_id}/${media_data[0].name}`;
//     }
//   } catch (error) {
//     sendLogToServer("Error getSingleMediaUrl fetching session:", error);
//     return null;
//   }
// };

export const deleteMedia = async (link) => {
  sendLogToServer("Delete media");

  try {
    const { data, error } = await supabase.storage
      .from("avatars")
      .remove([link]);

    return data;
  } catch (error) {
    sendLogToServer("Error fetching session:", error);
  }
};

export const getGender = async (user_id) => {
  sendLogToServer("Fetching getGender");

  try {
    let { data: userData } = await supabase
      .from("profiles")
      .select("gender")
      .eq("id", user_id);

    return userData;
  } catch (error) {
    sendLogToServer("Error fetching getGender session:", error);
  }
};
