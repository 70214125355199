import { supabase } from "supabase/supabaseClient";
import { sendLogToServer } from "./logging";

export const addUserPayment = async (payment_data) => {
  sendLogToServer("addUserPayment");
  try {
    const { data, error } = await supabase
      .from("payments")
      .insert([payment_data]);

    return data;
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error addUserPayment: ${error}`);
  }
};

// supabase function to call payments table and get latest payment of user
export const getUserPayment = async (user_id) => {
  sendLogToServer("getUserPayment");
  try {
    let { data: payment_data, error } = await supabase
      .from("payments")
      .select("*")
      .eq("user_id", user_id)
      .order("payment_at", { ascending: false })
      .limit(1);

    if (payment_data.length > 0) {
      if (new Date(payment_data[0].expiry_at) > new Date()) {
        return { payment_data: payment_data, has_paid: true };
      } else {
        return { payment_data: payment_data, has_paid: false };
      }
    } else {
      return { payment_data: payment_data, has_paid: false };
    }
  } catch (error) {
    // Handle any potential errors here
    sendLogToServer(`Error getUserPayment: ${error}`);
  }
};
