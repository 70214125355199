import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { searchOtherUsers } from "api/profiles";
import { calculateAge } from "api/profiles";
import { useTranslation } from "react-i18next";
import { UserCardSearch } from "helper/HomePageHelper";

export default function SearchBar({ isMobile, mobileClose }) {
  const { t } = useTranslation();

  const userData = useSelector((state) => state.userData);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState(false);

  useEffect(() => {
    return () => {
      // Cleanup the typing timeout when the component is unmounted
      clearTimeout(typingTimeout);
    };
  }, [typingTimeout]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setIsLoading(true);

        await searchOtherUsers(userData.data.id, searchQuery).then((data) => {
          setSearchResults(data);
        });

        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    if (searchQuery.trim() !== "") {
      // Clear the previous timeout
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set a new timeout to trigger the API call after 500 milliseconds (adjustable delay)
      const timeoutId = setTimeout(() => {
        fetchSearchResults();
      }, 500);

      setTypingTimeout(timeoutId);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    setIsSearchResultsOpen(true);
  };

  function searchResultCard(d) {
    return (
      <UserCardSearch key={d.id} profile_data={d} />
      // <div
      //   onClick={() => {
      //     navigate("/user_profile_page", {
      //       state: {
      //         user_id: d.id,
      //       },
      //     });
      //     setIsSearchResultsOpen(false);
      //   }}
      //   key={d.id}
      //   className="flex px-4 py-3 hover:bg-gray-100 "
      // >
      //   <div className="flex-shrink-0">
      //     <img
      //       className="rounded-full w-11 h-11"
      //       src="/docs/images/people/profile-picture-2.jpg"
      //       alt="Joseph image"
      //     />
      //   </div>
      //   <div className="w-full ps-3">
      //     <div className="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
      //       <span className="font-semibold text-gray-900 ">
      //         {d.first_name} {d.middle_name} {d.last_name}
      //       </span>{" "}
      //       and <span className="font-medium text-gray-900 ">{d.caste}</span>
      //     </div>
      //     <div className="text-xs text-blue-600 dark:text-blue-500">
      //       {d.user_code}
      //     </div>
      //   </div>
      // </div>
    );
  }

  return (
    <form className="w-full">
      <input
        type="text"
        className=" w-full px-4  py-3  text-sm text-gray-900 border border-gray-300 rounded-2xl bg-white"
        placeholder={`🔎    ${t("search")}...`}
        value={searchQuery}
        onChange={handleInputChange}
      />

      <span className="ms-1 mt-1 d-inline-block close searchbox-close">
        <i className="ti-close font-xs" onClick={mobileClose}></i>
      </span>
      {isSearchResultsOpen === false || searchQuery.length === 0 ? (
        <></>
      ) : (
        <div
          id="dropdownNotification"
          className="absolute z-[60] w-full right-0 bg-white divide-y divide-gray-100 rounded-lg shadow"
          aria-labelledby="dropdownNotificationButton"
        >
          {/* <div className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50  ">
            Search Results
          </div> */}
          <div className="divide-y divide-gray-100 mt-2">
            {searchResults.map((d) => searchResultCard(d))}
          </div>
        </div>
      )}
    </form>
  );
}
