import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const TranslationDropdown = forwardRef((props, ref) => {
  const { i18n } = useTranslation();

  const languages = [
    { value: "en", text: "English" },
    { value: "mr", text: "Marathi" },
  ];

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className="m-3 bg-white">
      <select onChange={handleChange} defaultValue="" ref={ref}>
        <option value="" disabled>
          {i18n.t("choose_language")}
        </option>
        {languages.map((item) => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
});

export default TranslationDropdown;
